import io from "socket.io-client";
import {
  clearStorage,
  getSessionData,
  initSessionData,
} from "./FilterComponentHelper";

let socketConnection = null;
export function registerToSocketConnection(callback, arbitrageType) {
  let socket = getSocketInstance();
  socket.off("marketData").on("marketData", (apiData) => {
    let sessionData = getSessionData();
    if (
      !sessionData ||
      !sessionData.currency ||
      !sessionData.investmentAmt ||
      !sessionData.exchangeRates
    ) {
      initSessionData().then((sessionData) => {
        callback(apiData, arbitrageType, sessionData);
      });
    } else {
      callback(apiData, arbitrageType, sessionData);
    }
  });
  socket.off("dataReady").on("dataReady", () => {
    socket.emit("requestData");
  });
  socket.off("sessionExpired").on("sessionExpired", () => {
    window.location = "/logout";
  });
  socket.emit("requestData");
}

export function logout() {
  let socket = getSocketInstance();
  socket.emit("logout");
  socket.removeAllListeners();
  socket.disconnect();
  clearStorage();
}

function getSocketInstance() {
  if (socketConnection == null) {
    socketConnection = io({ path: "/socket" });
  }
  return socketConnection;
}
