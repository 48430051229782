import React from "react";
import { Card, Accordion, Button } from "react-bootstrap";
import CommonTopbar from "../components/CommonTopbar";
import "../css/faq.scss";
import { RiUserSearchFill } from "react-icons/ri";
function Faq({ isAuthenticated }) {
  return (
    <div className="faq-parent-container">
      {!isAuthenticated && <CommonTopbar isAuthenticated={isAuthenticated} />}{" "}
      <div className="faq-container">
        <div className="faq-title">
          {" "}
          <RiUserSearchFill className="faq-icon" />
          Frequently asked questions
        </div>
        <div className="faq-content-container">
          <Accordion defaultActiveKey="0">
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="0">
                What is arbitrage?
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  Arbitrage describes the act of buying a coin in one market and
                  simultaneously selling it in another market at a higher price,
                  thereby enabling the user to profit from the temporary price
                  difference between the exchanges.
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="1">
                What is direct arbitrage?
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  Direct arbitrage is buying from exchange A and selling at
                  exchange B.
                  <br />
                  For example:
                  <ul>
                    <li>cost of LTC is ₹5000 in exchange A</li>
                    <li>cost of LTC is ₹5500 in exchange B</li>
                    <li>
                      Buying 1 LTC at ₹5000 from exchange A and selling at
                      exchange B would result in a profit of ₹500
                    </li>
                  </ul>
                  (Note: above example is for illustration in real-time
                  withdrawal fee and trading fee are accounted)
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="2">
                What is Intra exchange arbitrage?
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  An exchange can have submarkets like USDT, INR, BTC, etc.,
                  Intra exchange arbitrage, as the name suggests, it is finding
                  the arbitrage within the exchange
                  <br />
                  For example in exchange A:
                  <ul>
                    <li>Buy 1 LTC @ ₹5000 in USDT market</li>
                    <li>Sell of LTC is ₹5100 in BTC market</li>
                    <li>Resulting in profit of ₹100</li>
                  </ul>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="3">
                What is Triangular Arbitrage?
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="3">
                <Card.Body>
                  Triangular arbitrage is done across three exchanges
                  <br />
                  For example:
                  <ul>
                    <li>Buy from 1 LTC @ ₹5000 exchange A</li>
                    <li>
                      Sell 1 LTC @ ₹5500 at exchange B with a profit of $500
                    </li>
                    <li>Buy a different coin at exchange B for $5500</li>
                    <li>Sell at exchange C for profit.</li>
                  </ul>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="4">
                What is Loop arbitrage?
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="4">
                <Card.Body>
                  Loop arbitrage is similar to Triangular arbitrage as the name
                  suggests trade ends in a circle or loop
                  <br />
                  For example:
                  <ul>
                    <li>Buy from 1 LTC @ ₹5000 exchange A</li>
                    <li>
                      Sell 1 LTC @ ₹5500 at exchange B with profit of $500
                    </li>
                    <li>Buy a different coin at exchange B for $5500</li>
                    <li>Sell at exhchange A for profit.</li>
                  </ul>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="5">
                Trading charges accounted in the calculations?
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="5">
                <Card.Body>
                  Yes, Trading charges are accounted for in the arbitrage
                  calculation. Trading charges that are used in the calculation
                  can be found here
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="6">
                Withdrawal fee accounted in the calculations?
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="6">
                <Card.Body>
                  Yes, the Withdrawal fee is accounted for in the arbitrage
                  calculation for Direct, Triangular, and Loop arbitrages.
                  Exchange wise withdrawal fee that is used in the calculation
                  can be found here
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="7">
                What is the minimum amount need to do arbitrage trade?
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="7">
                <Card.Body>
                  It depends, We would recommend using at least $100 or
                  equivalent. Less than that would result in fewer arbitrage
                  opportunities, withdrawal fees can nullify up the profit. It
                  is good to remember that the withdrawal fee is the same for
                  any amount
                  <br />
                  i.e.,
                  <ul>
                    <li>if withdrawal fee for LTC is 0.025LTC in exchange A</li>
                    <li>it is the same for 1LTC and even 10LTC</li>
                  </ul>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="8">
                What are the things to check before making any trade?
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="8">
                <Card.Body>
                  BytesGap exploits the arbitrage opportunities to the best, We
                  solely depend on the data provided by each exchange.\ To be
                  safe from losses by any disinformation in the data keep a
                  check on the following things. *Check on the buy and sell
                  volumes *Check whether deposit and withdrawal is enabled for
                  the coin to be traded
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="9">
                Which is very profitable among Direct, Intra exchange,
                Triangular, Loop arbitrages?
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="9">
                <Card.Body>
                  I would recommend, Intra Exchange is very profitable given
                  arbitrage opportunities to exist. The reason being in Direct,
                  Triangular, and Loop arbitrages involves withdrawal from one
                  exchange to another, which consumes time and withdrawal fee.
                  Whereas in Intra exchange trades can be done instantly.
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="10">
                How is the arbitrage calculated?
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="10">
                <Card.Body>
                  Kindly refer to this page to know more about how we calculated
                  arbitrages
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </div>
      </div>
    </div>
  );
}

export default Faq;
