const xs = 480;
const sm = 576;
const md = 768;
const lg = 992;
const xl = 1200;

export const isXs = () => {
  return window.innerWidth > xs;
};

export const isSm = () => {
  return window.innerWidth > sm;
};
export const isMd = () => {
  return window.innerWidth > md;
};
export const isLg = () => {
  return window.innerWidth > lg;
};
export const isXl = () => {
  return window.innerWidth > xl;
};
