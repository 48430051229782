import React, { createContext, useState } from "react";

export const SidebarContext = createContext();

export const SidebarContextProvider = (props) => {
  const [sidebarState, setSidebarState] = useState({ isCollapsed: false, toggled: false });
  return (
    <SidebarContext.Provider value={[sidebarState, setSidebarState]}>
      {props.children}
    </SidebarContext.Provider>
  );
};
