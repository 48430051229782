import React from "react";
import { Nav } from "react-bootstrap";
import "./../css/welcomepage.scss";

function CommonTopbar({ isAuthenticated }) {
  return (
    <div className="nav-bar-container">
      <Nav.Link href="/">
        <div className="brand-name">BytesGap</div>
      </Nav.Link>
      <div className="nav-bar-links-container justify-content-end">
        <Nav.Link href="/dashboard/intra-exchange" className="hover-animation">
          Dashboard
        </Nav.Link>
        <Nav.Link
          href={isAuthenticated ? "/logout" : "/login"}
          className="hover-animation"
        >
          {isAuthenticated ? "Logout" : "Login"}
        </Nav.Link>
      </div>
    </div>
  );
}

export default CommonTopbar;
