import React, { useContext, useEffect, useState } from "react";
import { ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import { HiOutlineMenu } from "react-icons/hi";
import { isMd } from "../utils/Responsive";
import { AppContext } from "./../context/AppContext";
import { SidebarContext } from "./../context/SidebarContext";
import "./../css/topbar.scss";
import {
  getMinimumInvestmentAmt,
  getSessionData,
  initSessionData,
  saveSessionData,
} from "./../utils/FilterComponentHelper";
import { InvestmentAmtBar } from "./InvestmentAmtBar";

function Topbar({ isInvestmentbarVisible, setReload }) {
  const [appLevelData, setAppLevelData] = useContext(AppContext);
  const [sidebarState, setSidebarState] = useContext(SidebarContext);
  const [windowSize, setWindowSize] = useState();
  window.addEventListener("resize", () => {
    setWindowSize(window.innerWidth);
  });

  useEffect(() => {
    setSidebarState({ isCollapsed: false, toggled: false });
    setWindowSize();
    let sessionData = getSessionData();
    if (
      !sessionData ||
      !sessionData.currency ||
      !sessionData.investmentAmt ||
      !sessionData.exchangeRates
    ) {
      initSessionData().then((sessionData) => {
        setContextData(sessionData);
      });
    } else {
      setContextData(sessionData);
    }
  }, []);

  function setContextData(sessionData) {
    setAppLevelData((prevState) => {
      prevState = { ...prevState };
      prevState.currency = sessionData.currency;
      prevState.investmentAmt = sessionData.investmentAmt;
      return prevState;
    });
  }

  let onSelect = (eventKey, event) => {
    let sessionData = getSessionData();
    sessionData.currency = eventKey;
    sessionData.investmentAmt = getMinimumInvestmentAmt(eventKey);
    saveSessionData(sessionData);
    setAppLevelData((prevState) => {
      prevState = { ...prevState };
      prevState.currency = eventKey;
      prevState.investmentAmt = getMinimumInvestmentAmt(eventKey);
      return prevState;
    });
    setReload((reload) => !reload);
  };
  return (
    <React.Fragment>
      {appLevelData && (
        <div className="top-bar-container">
          <div className="brand-container px-1">
            <button
              className="btn menu-btn"
              onClick={() => {
                setSidebarState((prevState) => {
                  prevState = { ...prevState };
                  prevState.toggled = !isMd() ? !prevState.toggled : false;
                  prevState.isCollapsed = !isMd()
                    ? false
                    : !prevState.isCollapsed;
                  return prevState;
                });
              }}
            >
              <HiOutlineMenu className="menu-icon mx-2" />
            </button>
            <a href="/" className="brand-name">
              BytesGap
            </a>
          </div>
          {isInvestmentbarVisible && (
            <React.Fragment>
              {isMd() && (
                <div>
                  <InvestmentAmtBar></InvestmentAmtBar>
                </div>
              )}
              <div className="px-4 py-2 ">
                <DropdownButton
                  as={ButtonGroup}
                  key={"down"}
                  drop={"down"}
                  variant="dropdown"
                  title={appLevelData.currency}
                >
                  <Dropdown.Item eventKey="USD" onSelect={onSelect}>
                    $ USD
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="INR" onSelect={onSelect}>
                    ₹ INR
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="EUR" onSelect={onSelect}>
                    € EUR
                  </Dropdown.Item>
                </DropdownButton>
              </div>
            </React.Fragment>
          )}
        </div>
      )}
      {appLevelData && !isMd() && isInvestmentbarVisible && (
        <div className="mobile-investment-container">
          <InvestmentAmtBar></InvestmentAmtBar>
        </div>
      )}
    </React.Fragment>
  );
}

export default Topbar;
