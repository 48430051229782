import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter,HashRouter } from "react-router-dom";
import App from "./App";
import { AppContextProvider } from "./context/AppContext";
import { SidebarContextProvider } from "./context/SidebarContext";

ReactDOM.render(
  <React.StrictMode>
    <AppContextProvider>
      <SidebarContextProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </SidebarContextProvider>
    </AppContextProvider>
  </React.StrictMode>,
  document.getElementById("rooot")
);
