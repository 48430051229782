import React from "react";
import { Button, Form } from "react-bootstrap";
import { FaDonate } from "react-icons/fa";
import CommonTopbar from "../components/CommonTopbar";
import "../css/donate.scss";
function Donate({ isAuthenticated }) {
  return (
    <div className="donate-container">
      {!isAuthenticated && <CommonTopbar isAuthenticated={isAuthenticated} />}
      <div className="container">
        <div className="faq-title">
          {" "}
          <FaDonate className="faq-icon" />
          <span className="mx-3">Donate</span>
        </div>
        <DonateRowComponent
          symbol="BTC"
          name="Bitcoin(BTC)"
          address="1CN1nSyHSDZwz4ziruoZXjz7fzyKmdTyjJ"
          imageSrc={require("./../icons/icons/btc-qrcode.png")}
        />
        <DonateRowComponent
          symbol="XRP"
          name="Ripple(XRP)"
          address="rNxCw7HUbd51dvUMUyC7ju5XivXmCM1Bvr"
          imageSrc={require("./../icons/icons/xrp-qrcode.png")}
          destinationTag="681074846"
        />
      </div>
    </div>
  );
}

function DonateRowComponent({
  name,
  symbol,
  address,
  imageSrc,
  destinationTag,
}) {
  return (
    <React.Fragment>
      <h3>{name}</h3>
      <div className="donate-component-container">
        <div className="donate-component-item1">
          <div>Send your contributions to following Bitcoin Address</div>
          <hr></hr>
          <div>Destination address</div>
          <div className="donate-wallet-address">
            {address}
            <Button
              variant="success"
              size="sm"
              onClick={() => {
                navigator.clipboard.writeText(address);
              }}
            >
              Copy
            </Button>
          </div>
          <div className="font-x2-small">
            {" "}
            Please deposit only {symbol} to this address.
          </div>

          {destinationTag && (
            <React.Fragment>
              <br></br>
              <div>Destination Tag</div>
              <div className="donate-wallet-address">
                {destinationTag}
                <Button
                  variant="success"
                  size="sm"
                  onClick={() => {
                    navigator.clipboard.writeText(destinationTag);
                  }}
                >
                  Copy
                </Button>
              </div>
              <div className="font-x2-small">
                Adding Destination Tag is mandatory. If you do not add the
                correct tag depositing {symbol} will be lost forever.
              </div>
            </React.Fragment>
          )}
        </div>
        <div className="donate-component-item2">
          <div>Or scan this QR Code</div>
          <hr></hr>
          <img src={imageSrc} alt=""></img>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Donate;
