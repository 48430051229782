export default {
  currency: null,
  investmentAmt: null,
  exchangeRates: null,
  directFilter: {
    fromExchange: [],
    fromCoins: [],
    toExchange: [],
    toCoins: [],
  },
  intraExchangeFilter: {
    fromExchange: [],
    fromCoins: [],
    toExchange: [],
    toCoins: [],
  },
  loopFilter: {
    fromExchange: [],
    fromCoins: [],
    viaExchange: [],
    viaCoins: [],
    toExchange: [],
    toCoins: [],
  },
  triangularFilter: {
    fromExchange: [],
    fromCoins: [],
    viaExchange: [],
    viaCoins: [],
    toExchange: [],
    toCoins: [],
  },
};
