import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import React, { useEffect, useRef } from "react";
import "./../css/filter.scss";
import DirectFilter from "./filterpages/directfilter";
import IntraExchangeFilter from "./filterpages/intraexchangefilter";
import LoopFilter from "./filterpages/loopfilter";
import TriangularFilter from "./filterpages/triangularfilter";

export default function Filter(props) {
  const [value, setValue] = React.useState(0);
  let prevIndex = useRef(0);

  const handleChange = (event, newValue) => {
    prevIndex = newValue;
    setValue(newValue);
  };

  useEffect(() => {
    prevIndex.current = value;
  });

  return (
    <React.Fragment>
      <div className="filter-main-container">
        <Tabs
          value={value}
          onChange={handleChange}
          centered
          variant="fullWidth"
        >
          <Tab
            label="Direct"
            className="tab"
            classes={{ selected: "selected", colorSecondary: "indicator" }}
          ></Tab>
          <Tab label="Intra Exchange" className="tab"></Tab>
          <Tab label="Loop" className="tab"></Tab>
          <Tab label="Triangular" className="tab"></Tab>
        </Tabs>
        <div
          className={
            (prevIndex.current > value
              ? "slide-from-left"
              : "slide-from-right") + " filter-tabpanel-container"
          }
          key={value}
        >
          <TabPanel value={value} index={0}>
            <DirectFilter />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <IntraExchangeFilter />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <LoopFilter />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <TriangularFilter />
          </TabPanel>
        </div>
      </div>
    </React.Fragment>
  );
}

function TabPanel({ children, value, index }) {
  return (
    <div className="tabpanel">
      {value === index && <center>{children}</center>}
    </div>
  );
}
