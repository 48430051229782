import { Button } from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import SaveIcon from "@material-ui/icons/Save";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import ExchangeAndCoinSelect from "../../components/filtercomponents/ExchangeCoinSelect";
import Constants from "../../utils/Constants";
import {
  clearFilter,
  getCoinsOptionsFromData,
  getDefaultCoinsOptions as filterFromCoinsOptionsObj,
  getDefaultExchangeOptions as filterFromExchangeOptionsObj,
  getExchangeOptionsFromData,
  getFilter,
  getRemainingExchangeOptions,
  getRemainingSameExchangeOptions,
  saveFilter,
} from "../../utils/FilterComponentHelper";
import FilterData from "../../utils/FilterData";

export default function LoopFilter(props) {
  const [reload, setReload] = useState(true);

  const [loopFilter, setLoopFilter] = useState({
    exchangeOptions: [],
    coinsOptions: [],
    // From Exchange
    fromExchangeOptions: [],
    fromCoinsOptions: [],
    selectedFromExchangeOptions: [],
    selectedFromCoinsOptions: [],

    // via Exchange
    viaExchangeOptions: [],
    viaCoinsOptions: [],
    selectedViaExchangeOptions: [],
    selectedViaCoinsOptions: [],

    // To Exchange
    toExchangeOptions: [],
    toCoinsOptions: [],
    selectedToExchangeOptions: [],
    selectedToCoinsOptions: [],
    filterOption: getFilter(Constants.LOOP_FILTER),
  });

  useEffect(() => {
    initialize().then(() => {
      setReload(true);
    });
  }, [reload]);

  async function initialize() {
    return Axios.get("/api/trackedData/loopFilter")
      .then((res) => {
        let exchangeOptions = getExchangeOptionsFromData(res.data);
        let coinsOptions = getCoinsOptionsFromData(res.data);
        let filterOption = getFilter(Constants.LOOP_FILTER) || FilterData;

        let tempViaExchangeOptions = getRemainingExchangeOptions(
          exchangeOptions,
          filterOption.fromExchange.concat(filterOption.toExchange)
        );
        //here finding out the same remaining exchanges of To exchange
        // for example if filterOption.toExchange has BitBnsUSDT it will return BitBNSUSDT + BitBnsINS
        let fromExchangeOptions = getRemainingSameExchangeOptions(
          exchangeOptions,
          filterOption.toExchange,
          false
        );
        // with the above filtered result viaExchange Options also should be ignored to satisfy the
        // loop concept
        fromExchangeOptions = getRemainingExchangeOptions(
          fromExchangeOptions,
          filterOption.viaExchange
        );

        //same as fromExchangeOptions above
        let toExchangeOptions = getRemainingSameExchangeOptions(
          exchangeOptions,
          filterOption.fromExchange,
          false
        );

        toExchangeOptions = getRemainingExchangeOptions(
          toExchangeOptions,
          filterOption.viaExchange
        );

        setLoopFilter({
          exchangeOptions: exchangeOptions,
          coinsOptions: coinsOptions,
          fromCoinsOptions: coinsOptions,
          viaCoinsOptions: coinsOptions,
          toCoinsOptions: coinsOptions,
          filterOption: filterOption,

          // From Exchange
          fromExchangeOptions: fromExchangeOptions,

          selectedFromExchangeOptions: filterFromExchangeOptionsObj(
            exchangeOptions,
            filterOption.fromExchange
          ),

          selectedFromCoinsOptions: filterFromCoinsOptionsObj(
            coinsOptions,
            filterOption.fromCoins
          ),

          // From Exchange
          viaExchangeOptions: tempViaExchangeOptions,

          selectedViaExchangeOptions: filterFromExchangeOptionsObj(
            exchangeOptions,
            filterOption.viaExchange
          ),

          selectedViaCoinsOptions: filterFromCoinsOptionsObj(
            coinsOptions,
            filterOption.viaCoins
          ),

          // To Exchange
          toExchangeOptions: toExchangeOptions,

          selectedToCoinsOptions: filterFromCoinsOptionsObj(
            coinsOptions,
            filterOption.toCoins
          ),
          selectedToExchangeOptions: filterFromExchangeOptionsObj(
            exchangeOptions,
            filterOption.toExchange
          ),
        });
      })
      .catch((err) => {
        console.log("Unable to fetch the exchanges list");
      });
  }

  /**
   * Based on selected items from the `Via` ExchangeSelect set the `To` and `From`
   * ExchangeSelect's options
   * @param {*} selectedExchangeOptions
   */
  function fromExchangeSelectCallback(selectedExchangeOptions) {
    let toExchangeOptions = remainingSameExchange(selectedExchangeOptions);
    let viaExchangeOptions = getRemainingExchangeOptions(
      loopFilter.exchangeOptions,
      selectedExchangeOptions.concat(loopFilter.selectedToExchangeOptions)
    );
    setLoopFilter((prevState) => {
      prevState = { ...prevState };
      prevState.viaExchangeOptions = viaExchangeOptions;
      prevState.toExchangeOptions = toExchangeOptions;
      // For storing reults
      prevState.selectedFromExchangeOptions = selectedExchangeOptions;
      return prevState;
    });
  }
  function fromCoinSelectCallback(selectedCoinsOptions) {
    setLoopFilter((prevState) => {
      prevState = { ...prevState };
      // For storing reults
      prevState.selectedFromCoinsOptions = selectedCoinsOptions;
      return prevState;
    });
  }

  function remainingSameExchange(selectedOptions) {
    let temp = getRemainingSameExchangeOptions(
      loopFilter.exchangeOptions,
      selectedOptions,
      false
    );
    return temp;
  }

  /**
   * Based on selected items from the `To` ExchangeSelect set the `To` and `Via`
   * ExchangeSelect's options
   * @param {*} selectedExchangeOptions
   */
  function viaExchangeSelectCallback(selectedExchangeOptions) {
    let remainingFromExchanges = getRemainingSameExchangeOptions(
      loopFilter.exchangeOptions,
      loopFilter.selectedToExchangeOptions,
      false
    );
    remainingFromExchanges = getRemainingExchangeOptions(
      remainingFromExchanges,
      selectedExchangeOptions
    );

    let remainingToExchanges = getRemainingSameExchangeOptions(
      loopFilter.exchangeOptions,
      loopFilter.selectedFromExchangeOptions,
      false
    );

    remainingToExchanges = getRemainingExchangeOptions(
      remainingToExchanges,
      selectedExchangeOptions
    );

    setLoopFilter((prevState) => {
      prevState = { ...prevState };
      prevState.fromExchangeOptions = remainingFromExchanges;
      prevState.toExchangeOptions = remainingToExchanges;
      // For storing reults
      prevState.selectedViaExchangeOptions = selectedExchangeOptions;
      return prevState;
    });
  }
  function viaCoinSelectCallback(selectedCoinsOptions) {
    setLoopFilter((prevState) => {
      prevState = { ...prevState };
      // For storing reults
      prevState.selectedViaCoinsOptions = selectedCoinsOptions;
      return prevState;
    });
  }
  /**
   * Based on selected items from the `To` ExchangeSelect set the `To` and `Via`
   * ExchangeSelect's options
   * via exchange options = (exchangeOptions - selectedOptions)
   * From exchange options = (exchangeOptions - Same exchange including same submarket options(selectedOptions))
   * @param {*} selectedExchangeOptions
   * @param {*} selectedCoinsOption
   */
  function toExchangeSelectCallback(selectedExchangeOptions) {
    let fromExchangeOptions = remainingSameExchange(selectedExchangeOptions);
    let viaExchangeOptions = getRemainingExchangeOptions(
      loopFilter.exchangeOptions,
      selectedExchangeOptions.concat(loopFilter.selectedFromExchangeOptions)
    );

    setLoopFilter((prevState) => {
      prevState = { ...prevState };
      prevState.viaExchangeOptions = viaExchangeOptions;
      prevState.fromExchangeOptions = fromExchangeOptions;

      // For storing reults
      prevState.selectedToExchangeOptions = selectedExchangeOptions;
      return prevState;
    });
  }

  function toCoinSelectCallback(selectedCoinsOptions) {
    setLoopFilter((prevState) => {
      prevState = { ...prevState };
      // For storing reults
      prevState.selectedToCoinsOptions = selectedCoinsOptions;
      return prevState;
    });
  }

  function isEverythingLoaded() {
    return (
      loopFilter.exchangeOptions.length > 0 &&
      loopFilter.coinsOptions.length > 0 &&
      reload
    );
  }
  return (
    <React.Fragment>
      {isEverythingLoaded() && (
        <div>
          <center>
            <ExchangeAndCoinSelect
              key="loopFrom"
              keyName="loopFrom"
              title="From"
              exchangeSelectCallback={fromExchangeSelectCallback}
              coinSelectCallback={fromCoinSelectCallback}
              selectedExchangeOptions={loopFilter.selectedFromExchangeOptions}
              selectedCoinsOptions={loopFilter.selectedFromCoinsOptions}
              exchangeOptions={loopFilter.fromExchangeOptions}
              coinsOptions={loopFilter.fromCoinsOptions}
              showCoinSelect={true}
            ></ExchangeAndCoinSelect>
          </center>
          <center>
            <ExchangeAndCoinSelect
              key="loopVia"
              title="Via"
              keyName="loopVia"
              exchangeSelectCallback={viaExchangeSelectCallback}
              coinSelectCallback={viaCoinSelectCallback}
              selectedExchangeOptions={loopFilter.selectedViaExchangeOptions}
              selectedCoinsOptions={loopFilter.selectedViaCoinsOptions}
              exchangeOptions={loopFilter.viaExchangeOptions}
              coinsOptions={loopFilter.viaCoinsOptions}
              showCoinSelect={true}
            ></ExchangeAndCoinSelect>
          </center>
          <center>
            <ExchangeAndCoinSelect
              key="loopTo"
              keyName="loopTo"
              title="To"
              exchangeSelectCallback={toExchangeSelectCallback}
              coinSelectCallback={toCoinSelectCallback}
              selectedExchangeOptions={loopFilter.selectedToExchangeOptions}
              selectedCoinsOptions={loopFilter.selectedToCoinsOptions}
              exchangeOptions={loopFilter.toExchangeOptions}
              coinsOptions={loopFilter.toCoinsOptions}
              showCoinSelect={false}
            ></ExchangeAndCoinSelect>
          </center>
          <center>
            <Button
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              className="filter-save-button"
              onClick={() => {
                saveFilter(Constants.LOOP_FILTER, {
                  fromExchange: loopFilter.selectedFromExchangeOptions,
                  fromCoins: loopFilter.selectedFromCoinsOptions,
                  viaExchange: loopFilter.selectedViaExchangeOptions,
                  viaCoins: loopFilter.selectedViaCoinsOptions,
                  toExchange: loopFilter.selectedToExchangeOptions,
                  toCoins: loopFilter.selectedToCoinsOptions,
                });
              }}
            >
              Save
            </Button>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<DeleteForeverIcon />}
              className="filter-clear-button"
              onClick={() => {
                clearFilter(Constants.LOOP_FILTER);
                setReload(false);
              }}
            >
              Discard
            </Button>
          </center>
        </div>
      )}
    </React.Fragment>
  );
}
