import React, { createContext, useState } from "react";

export const AppContext = createContext();

export const AppContextProvider = (props) => {
  const [appLevelData, setAppLevelData] = useState(null);
  return (
    <AppContext.Provider value={[appLevelData, setAppLevelData]}>
      {props.children}
    </AppContext.Provider>
  );
};
