import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { AppContext } from "../context/AppContext";
import "./../css/investment-amt-bar.scss";
import {
  getMinimumInvestmentAmt,
  getSessionData,
  saveSessionData,
} from "./../utils/FilterComponentHelper";
export function InvestmentAmtBar() {
  const [appLevelData, setAppLevelData] = useContext(AppContext);
  const [investmentAmt, setInvestmentAmt] = useState(
    appLevelData.investmentAmt
  );

  useEffect(() => {
    setInvestmentAmt(appLevelData.investmentAmt);
  }, [appLevelData]);

  const onSet = (e) => {
    let minAmt = getMinimumInvestmentAmt(appLevelData.currency);
    if (investmentAmt >= minAmt) {
      setAppLevelData((prevState) => {
        prevState = { ...prevState };
        let sessionData = getSessionData();
        sessionData.investmentAmt = investmentAmt;
        prevState.investmentAmt = investmentAmt;
        saveSessionData(sessionData);
        return prevState;
      });
    } else {
      setInvestmentAmt(minAmt);
    }
    window.location.reload();
  };
  return (
    <React.Fragment>
      <div className="investment-container">
        <Form>
          <Form.Row className="align-items-center">
            <Col xs="auto">
              <Form.Label className="label">
                Investment Amount in{" "}
                <span className="currency">{appLevelData.currency}</span>
              </Form.Label>
            </Col>
            <Col>
              <Form.Control
                key={appLevelData.currency}
                className="mb-2"
                type="number"
                aria-describedby="inputGroupPrepend"
                min={getMinimumInvestmentAmt(appLevelData.currency)}
                value={investmentAmt}
                placeholder="Enter amount"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    onSet(e);
                  }
                }}
                onChange={(e) => {
                  setInvestmentAmt(e.target.value);
                }}
              />
            </Col>
            <Col xs="auto">
              <Button className="mb-2" onClick={onSet}>
                Set
              </Button>
            </Col>
          </Form.Row>
        </Form>
      </div>
    </React.Fragment>
  );
}
