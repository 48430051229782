import Axios from "axios";
import React from "react";
import "./../css/exchange-coin-select.scss";
import Constants from "./Constants";
import FilterData from "./FilterData";

const storage = sessionStorage;
/**
 * Returns the exchange option from the data
 * @param {
 *   [{
 *     "currency": "BTC",
 *     "exchange": "bitbns",
 *     "subExchange": "USDT"
 *   }]
 * } data
 *
 *
 * @returns options
 * [value: {
 *       exchange: "bitbns",
 *       subExchange: "USDT",
 *       isDisabled: "boolean",
 *     }]
 */
export function getExchangeOptionsFromData(data) {
  let options = [];
  let map = new Map();
  for (let obj of Object.values(data)) {
    let exchange = obj.exchange;
    let subExchange = obj.subExchange;
    map.set(exchange + "_" + subExchange, true);
  }
  for (let key of map.keys()) {
    let [exchange, subExchange] = key.split("_");

    options.push({
      key: exchange + subExchange,
      value: {
        exchange: exchange,
        subExchange: subExchange,
      },
      label: getCustomExchangeSelectLabel(exchange, subExchange),
    });
  }

  sortExchangeOptions(options);
  return options;
}

/**
 * Returns the coin option from the data
 * @param {
 *   [{
 *     "currency": "BTC",
 *     "exchange": "bitbns",
 *     "subExchange": "USDT"
 *   }]
 * } data
 *
 *
 * @returns options
 * [value: {
 *       currency: "XRP",
 *       exchange: "bitbns",
 *       subExchange: "USDR",
 *       isDisabled: "boolean",
 *     }]
 */
export function getCoinsOptionsFromData(data) {
  let coinsOptions = [];
  for (let value of Object.values(data)) {
    let key = value.currency + value.exchange + value.subExchange;
    coinsOptions.push({
      key: key,
      value: {
        currency: value.currency,
        exchange: value.exchange,
        subExchange: value.subExchange,
      },
      label: getCustomCoinSelectLabel(
        value.currency,
        value.exchange,
        value.subExchange
      ),
    });
  }
  return coinsOptions;
}

/**
 * Returns the custom label for exchange options along with subexchange
 * @param {*} exchange
 * @param {*} subExchange
 */

export function getCustomExchangeSelectLabel(exchange, subExchange) {
  return (
    <>
      {exchange.toUpperCase()}
      <span className="exchange-text font-small">
        {" (" + subExchange + ")"}
      </span>
    </>
  );
}

/**
 * Returns the custom label for coins options along with subexchange
 * @param {*} currency
 * @param {*} exchange
 * @param {*} subExchange
 */

function getCustomCoinSelectLabel(currency, exchange, subExchange) {
  return (
    <>
      {currency}
      <span className="exchange-text font-x-small">
        {" (" + exchange + " - " + subExchange + ")"}
      </span>
    </>
  );
}

/**
 * checks if coinoptions are equal
 * @param {*} coinsOption1
 * @param {*} coinsOption2
 */
export function isCoinsOptionsEqual(coinsOption1, coinsOption2) {
  return (
    coinsOption1.value.exchange === coinsOption2.value.exchange &&
    coinsOption1.value.currency === coinsOption2.value.currency &&
    coinsOption1.value.subExchange === coinsOption2.value.subExchange
  );
}

/**
 * checks if exchangeoptions are equal including subexchange
 * @param {*} exchangeOption1
 * @param {*} exchangeOption2
 */
export function isExchangesOptionsEqual(exchangeOption1, exchangeOption2) {
  return (
    (exchangeOption1 && exchangeOption1.value.exchange) ===
      (exchangeOption2 && exchangeOption2.value.exchange) &&
    (exchangeOption1 && exchangeOption1.value.subExchange) ===
      (exchangeOption2 && exchangeOption2.value.subExchange)
  );
}

/**
 * Filters the coin options by exchange
 * @param {*} exchangeOptions
 * @param {*} coinsOptions
 */
export function getCoinsOptionByExchanges(exchangeOptions, coinsOptions) {
  let coinsOptionsByExchange = [];
  if (exchangeOptions && coinsOptions) {
    exchangeOptions.forEach((exchangeOption) => {
      coinsOptions.forEach((coinsOption) => {
        if (
          coinsOption.value.exchange === exchangeOption.value.exchange &&
          coinsOption.value.subExchange === exchangeOption.value.subExchange
        ) {
          coinsOptionsByExchange.push(coinsOption);
        }
      });
    });
  }
  sortCoinsOptions(coinsOptionsByExchange);
  return coinsOptionsByExchange;
}

/**
 * Returns the list of remaining group of exchange options from the universal group
 *
 * @param {*} exchangeOptions Options containing all exchanges
 * @param {*} toBeIgnoredExchangeOptions options to be skipped from the cumulative options
 */
export function getRemainingExchangeOptions(
  exchangeOptions,
  toBeIgnoredExchangeOptions
) {
  if (!toBeIgnoredExchangeOptions) {
    return exchangeOptions;
  }
  let remainingExchangeCoinsOptions = [...exchangeOptions];

  remainingExchangeCoinsOptions.forEach((exchangeOption, i) => {
    toBeIgnoredExchangeOptions.forEach((toBeIgnoredExchangeOption) => {
      if (isSameExchange(exchangeOption, toBeIgnoredExchangeOption)) {
        remainingExchangeCoinsOptions[i] = null;
      }
    });
  });
  remainingExchangeCoinsOptions = remainingExchangeCoinsOptions.filter(
    (element) => {
      return element !== null;
    }
  );
  return remainingExchangeCoinsOptions;
}

/**
 * Returns the list of remaining same group of exchange options from the universal group
 *
 * @param {*} exchangeOptions Options containing all exchanges
 * @param {*} toBeIgnoredExchangeOptions options to be skipped from the cumulative options
 * @param {*} includeSubmarket when it is false it includes the submarket data in the result
 */
export function getRemainingSameExchangeOptions(
  exchangeOptions,
  toBeIgnoredExchangeOptions,
  includeSubmarket = true
) {
  if (!toBeIgnoredExchangeOptions || toBeIgnoredExchangeOptions.length === 0) {
    return exchangeOptions;
  }
  let remainingExchangeCoinsOptions = [...exchangeOptions];

  remainingExchangeCoinsOptions.forEach((exchangeOption, i) => {
    toBeIgnoredExchangeOptions.forEach((toBeIgnoredExchangeOption) => {
      // when the following condition passes we add the null
      // 1 - checks if it is not same exchange (excludes submarket check)
      // 2 - also checks if submarket is equal
      // incluedSubmarket - flag is needed in loop arbitrage filter
      if (
        !isSameExchange(exchangeOption, toBeIgnoredExchangeOption) ||
        (includeSubmarket &&
          isExchangesOptionsEqual(exchangeOption, toBeIgnoredExchangeOption))
      ) {
        remainingExchangeCoinsOptions[i] = null;
      }
    });
  });
  remainingExchangeCoinsOptions = remainingExchangeCoinsOptions.filter(
    (element) => {
      return element !== null;
    }
  );
  return remainingExchangeCoinsOptions;
}

/**
 * Util method to check if exchange options are equal
 * @param {*} exchangeOption1
 * @param {*} exchangeOption2
 */
export function isSameExchange(exchangeOption1, exchangeOption2) {
  return (
    (exchangeOption1 && exchangeOption1.value.exchange) ===
    (exchangeOption2 && exchangeOption2.value.exchange)
  );
}

/**
 * Util for sorting the coins options
 * @param {*} coinsOptions
 */

export function sortCoinsOptions(coinsOptions) {
  coinsOptions.sort((val1, val2) => {
    let ex1 =
      val1.value.currency + val1.value.exchange + val1.value.subExchange;
    let ex2 =
      val2.value.currency + val2.value.exchange + val2.value.subExchange;
    return ex1.localeCompare(ex2);
  });
}

/**
 * Util for sorting the exchange options
 * @param {*} exchangeOptions
 */
function sortExchangeOptions(exchangeOptions) {
  exchangeOptions.sort((val1, val2) => {
    let ex1 = val1.value.exchange + val1.value.subExchange;
    let ex2 = val2.value.exchange + val2.value.subExchange;
    return ex1.localeCompare(ex2);
  });
}

export function saveFilter(filterType, options) {
  let filter = getSessionData();
  switch (filterType) {
    case Constants.DIRECT_FILTER: {
      filter.directFilter = options;
      break;
    }
    case Constants.INTRA_EXCHANGE_FILTER: {
      filter.intraExchangeFilter = options;
      break;
    }
    case Constants.LOOP_FILTER: {
      filter.loopFilter = options;
      break;
    }
    case Constants.TRIANGULAR_FILTER: {
      filter.triangularFilter = options;
      break;
    }
    default:
  }
  storage.setItem(Constants.BYTES_GAP_FILTER, JSON.stringify(filter));
}

export function saveSessionData(data) {
  storage.setItem(Constants.BYTES_GAP_FILTER, JSON.stringify(data));
}

export function getSessionData() {
  let sessionData = JSON.parse(storage.getItem(Constants.BYTES_GAP_FILTER));
  if (!sessionData) {
    saveSessionData(FilterData);
    return FilterData;
  }
  return sessionData;
}

export function clearFilter(filterType) {
  let filterOptions = FilterData;

  switch (filterType) {
    case Constants.DIRECT_FILTER: {
      saveFilter(filterType, filterOptions.directFilter);
      break;
    }
    case Constants.INTRA_EXCHANGE_FILTER: {
      saveFilter(filterType, filterOptions.intraExchangeFilter);
      break;
    }
    case Constants.LOOP_FILTER: {
      saveFilter(filterType, filterOptions.loopFilter);
      break;
    }
    case Constants.TRIANGULAR_FILTER: {
      saveFilter(filterType, filterOptions.triangularFilter);
      break;
    }
    default:
  }
}

export function getFilter(filterType) {
  let filter = storage.getItem(Constants.BYTES_GAP_FILTER);
  filter = filter ? JSON.parse(filter) : FilterData;
  if (filter) {
    switch (filterType) {
      case Constants.DIRECT_FILTER: {
        filter = filter.directFilter;
        break;
      }
      case Constants.INTRA_EXCHANGE_FILTER: {
        filter = filter.intraExchangeFilter;
        break;
      }
      case Constants.LOOP_FILTER: {
        filter = filter.loopFilter;
        break;
      }
      case Constants.TRIANGULAR_FILTER: {
        filter = filter.triangularFilter;
        break;
      }
      default:
    }
  }
  return filter;
}

export function getDefaultExchangeOptions(allExchangeOptions, exchangeOptions) {
  let temp = allExchangeOptions
    .filter((element) => {
      let foundElement = exchangeOptions.find((value) => {
        return isExchangesOptionsEqual(element, value);
      });
      if (foundElement) {
        return foundElement;
      }
      return null;
    })
    .filter((element) => element != null);
  return temp;
}

export function getDefaultCoinsOptions(allCoinsOptions, coinsOptions) {
  let temp = allCoinsOptions
    .filter((element) => {
      let foundElement = coinsOptions.find((value) => {
        return isCoinsOptionsEqual(element, value);
      });
      if (foundElement) {
        return foundElement;
      }
      return null;
    })
    .filter((element) => element != null);
  return temp;
}

export function getFilterForServerRequest() {
  let sessionData = getSessionData();
  let json = {
    currency: sessionData.currency,
    investmentAmt: sessionData.investmentAmt,
    exchangeRates: sessionData.exchangeRates,
    directFilter: {
      fromExchange: getValuesFromFilterObject(
        sessionData.directFilter.fromExchange
      ),
      fromCoins: getValuesFromFilterObject(sessionData.directFilter.fromCoins),
      toExchange: getValuesFromFilterObject(
        sessionData.directFilter.toExchange
      ),
    },
    intraExchangeFilter: {
      fromExchange: getValuesFromFilterObject(
        sessionData.intraExchangeFilter.fromExchange
      ),
      fromCoins: getValuesFromFilterObject(
        sessionData.intraExchangeFilter.fromCoins
      ),
      toExchange: getValuesFromFilterObject(
        sessionData.intraExchangeFilter.toExchange
      ),
    },
    loopFilter: {
      fromExchange: getValuesFromFilterObject(
        sessionData.loopFilter.fromExchange
      ),
      fromCoins: getValuesFromFilterObject(sessionData.loopFilter.fromCoins),
      viaExchange: getValuesFromFilterObject(
        sessionData.loopFilter.viaExchange
      ),
      viaCoins: getValuesFromFilterObject(sessionData.loopFilter.viaCoins),
      toExchange: getValuesFromFilterObject(sessionData.loopFilter.toExchange),
    },
    triangularFilter: {
      fromExchange: getValuesFromFilterObject(
        sessionData.triangularFilter.fromExchange
      ),
      fromCoins: getValuesFromFilterObject(
        sessionData.triangularFilter.fromCoins
      ),
      viaExchange: getValuesFromFilterObject(
        sessionData.triangularFilter.viaExchange
      ),
      viaCoins: getValuesFromFilterObject(
        sessionData.triangularFilter.viaCoins
      ),
      toExchange: getValuesFromFilterObject(
        sessionData.triangularFilter.toExchange
      ),
    },
  };
  return JSON.stringify(json);
}

function getValuesFromFilterObject(item) {
  return item.map((element) => element.value);
}

/**
 * Cleanest way would be getting conversion rate from server
 */
export function getMinimumInvestmentAmt(selectedCurrency) {
  switch (selectedCurrency) {
    case "USD":
      return Constants.MIN_USD;
    case "EUR":
      return Constants.MIN_EUR;
    case "INR":
      return Constants.MIN_INR;
    default:
  }
}

export function calculateInvestmentInUSD(sessionData) {
  let investmentAmt = +sessionData.investmentAmt;
  let currency = sessionData.currency;
  let exchangeRate = +sessionData.exchangeRates[currency];
  return (investmentAmt = investmentAmt / exchangeRate);
}

export function calculateValueInSelectedCurrency(sessionData, value) {
  let currency = sessionData.currency;
  let exchangeRate = +sessionData.exchangeRates[currency];
  return (value = value * exchangeRate);
}

export async function initSessionData() {
  let sessionData = getSessionData();
  return Axios.get("/api/initdata").then((res) => {
    let defaultCurrency = res.data.defaultCurrency;
    sessionData.currency = defaultCurrency;
    sessionData.investmentAmt = getMinimumInvestmentAmt(defaultCurrency);
    sessionData.exchangeRates = res.data.exchangeRates;
    saveSessionData(sessionData);
    return sessionData;
  });
}

export function clearStorage() {
  storage.clear();
}

export function getLocaleByCurrencyCode() {
  let sessionData = getSessionData();
  let fiat = sessionData.currency;
  if (fiat === "INR") {
    return "en-IN";
  } else if (fiat === "USD") {
    return "en-US";
  } else {
    return "en-GB";
  }
}

export function getCurrencyCodeSymbol() {
  let sessionData = getSessionData();
  let fiat = sessionData.currency;
  if (fiat === "INR") {
    return {
      style: "currency",
      currency: "INR",
    };
  } else if (fiat === "USD") {
    return {
      style: "currency",
      currency: "USD",
    };
  } else {
    return {
      style: "currency",
      currency: "EUR",
    };
  }
}
