import Axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import Cookie from "js-cookie";
import React, { lazy, Suspense, useState } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";
import Sidebar from "./components/Sidebar";
import Topbar from "./components/Topbar";
import "./css/app.scss";
import About from "./pages/about";
import Donate from "./pages/donate";
import Faq from "./pages/faq";
import Filter from "./pages/filter";
import LivePrice from "./pages/liveprice";
import Login from "./pages/login";
import Logout from "./pages/logout";
import Profile from "./pages/profile";
import ProtectedRoute from "./routes/ProtectedRoute";

const ArbitrageBoard = lazy(() => import("./components/ArbitrageBoard"));
const WelcomePage = lazy(() => import("./pages/welcomepage"));

function App() {
  const isAuthenticated = Cookie.get("B_A_USER") === "YES";
  const [reload, setReload] = useState(false);

  TopBarProgress.config({
    barThickness: 3,
    barColors: {
      0: "#ffffff",
      0.5: "#198a22",
      1: "#ffffff",
    },
    shadowColor: "#ffffff",
    shadowBlur: 0,
  });
  let location = useLocation();
  let isTopAndSidebarVisible =
    !["/", "/login", "/logout"].includes(location.pathname) && isAuthenticated;
  let isInvestmentbarVisible = [
    "/dashboard/direct",
    "/dashboard/intra-exchange",
    "/dashboard/loop",
    "/dashboard/triangular",
    "/liveprice",
  ].includes(location.pathname);

  return (
    <React.Fragment>
      <div className="root-container">
        {isTopAndSidebarVisible && (
          <div className="dashboard-topbar">
            <Topbar
              isInvestmentbarVisible={isInvestmentbarVisible}
              setReload={setReload}
            ></Topbar>
          </div>
        )}
        <div className="dashboard-content-container">
          {isTopAndSidebarVisible && (
            <div className="dashboard-side-bar">
              <Sidebar />
            </div>
          )}
          <div className="dashboard-content">
            <Suspense fallback={<TopBarProgress />}>
              <Switch>
                <Route
                  path="/"
                  exact={true}
                  render={(props) => (
                    <WelcomePage
                      isAuthenticated={isAuthenticated}
                      {...props}
                    ></WelcomePage>
                  )}
                />
                <Route
                  path="/faq"
                  exact={true}
                  render={(props) => (
                    <Faq isAuthenticated={isAuthenticated} {...props}></Faq>
                  )}
                />
                <Route
                  path="/donate"
                  exact={true}
                  render={(props) => (
                    <Donate
                      isAuthenticated={isAuthenticated}
                      {...props}
                    ></Donate>
                  )}
                  
                />
                <Route path="/login" exact={true} component={Login} />
                <Route
                  path="/api/eggPrices/namakkal"
                  exact={true}
                  render={async (props) => {
                    return await Axios.get("/api/eggPrices/namakkal");
                  }}
                />
                <ProtectedRoute
                  path="/dashboard/direct"
                  exact={true}
                  isAuthenticated={isAuthenticated}
                  arbitrage="directData"
                  render={(props) => (
                    <ArbitrageBoard
                      arbitrageType="directData"
                      {...props}
                    ></ArbitrageBoard>
                  )}
                />
                <ProtectedRoute
                  path="/dashboard/intra-exchange"
                  exact={true}
                  isAuthenticated={isAuthenticated}
                  render={(props) => (
                    <ArbitrageBoard
                      arbitrageType="intraExchangeArbitragesData"
                      {...props}
                    ></ArbitrageBoard>
                  )}
                />
                <ProtectedRoute
                  path="/dashboard/loop"
                  exact={true}
                  isAuthenticated={isAuthenticated}
                  render={(props) => (
                    <ArbitrageBoard
                      arbitrageType="loopData"
                      {...props}
                    ></ArbitrageBoard>
                  )}
                />
                <ProtectedRoute
                  path="/dashboard/triangular"
                  exact={true}
                  isAuthenticated={isAuthenticated}
                  render={(props) => (
                    <ArbitrageBoard
                      arbitrageType="triangularData"
                      {...props}
                    ></ArbitrageBoard>
                  )}
                />
                <ProtectedRoute
                  path="/filter"
                  exact={true}
                  isAuthenticated={isAuthenticated}
                  render={(props) => <Filter {...props}></Filter>}
                />
                <ProtectedRoute
                  path="/profile"
                  exact={true}
                  isAuthenticated={isAuthenticated}
                  render={(props) => <Profile {...props}></Profile>}
                />
                <ProtectedRoute
                  path="/logout"
                  exact={true}
                  isAuthenticated={isAuthenticated}
                  component={Logout}
                />
                <ProtectedRoute
                  path="/liveprice"
                  exact={true}
                  isAuthenticated={isAuthenticated}
                  render={(props) => <LivePrice {...props}></LivePrice>}
                />
                <ProtectedRoute
                  path="/about"
                  exact={true}
                  isAuthenticated={isAuthenticated}
                  component={About}
                />
              </Switch>
            </Suspense>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default App;
