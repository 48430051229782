import Axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./../css/profile.scss";

export default function Profile(props) {
  const [profile, setProfile] = useState();

  useEffect(() => {
    Axios.get("/api/profile").then((response) => {
      let data = response.data;
      setProfile({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        defaultCurrency: data.defaultCurrency,
        subscriptionExpiry: data.subscriptionExpiry,
        phone: data.phone,
        country: data.country,
        countryCode: data.countryCode,
      });
    });
  }, []);

  const onDefaultCurrencyChange = (event) => {
    let defaultCurrency = event.target.value;
    setProfile((previousState) => {
      previousState.defaultCurrency = defaultCurrency;
      return previousState;
    });
  };

  const onSubmit = (e) => {
    Axios.post("/api/profile", profile).then((res) => {
      console.log("Data Saved");
    });
  };

  return (
    <div className="profile-container">
      {profile && (
        <Table borderless>
          <thead>
            <tr>
              <th colSpan="2">
                <center>Basic Information</center>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="info">Name</td>
              <td>{profile.firstName + " " + profile.lastName}</td>
            </tr>
            <tr>
              <td className="info">email:</td>
              <td>{profile.email}</td>
            </tr>
            <tr>
              <td className="info">phone:</td>
              <td>
                <PhoneInput
                  containerClass="profile-field"
                  enableAreaCodes={false}
                  countryCodeEditable={false}
                  country={profile.countryCode || "us"}
                  placeholder="Enter phone number"
                  value={profile.phone}
                  onChange={(value, country, e, formattedValue) => {
                    setProfile((previousState) => {
                      previousState = { ...previousState };
                      previousState.phone = value;
                      // country.name ref from lib
                      previousState.country = country.name;
                      previousState.countryCode = country.countryCode;
                      return previousState;
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td className="info">Country:</td>
              <td colSpan="2">
                <Form.Control
                  className="profile-field-country"
                  type="text"
                  readOnly
                  disabled
                  defaultValue={profile.country}
                />
              </td>
            </tr>
            <tr>
              <td className="info">Subscription Expiry:</td>
              <td>
                {moment
                  .utc(new Date(profile.subscriptionExpiry))
                  .format("MMM Do YYYY h:mm a")}
              </td>
            </tr>
            <tr>
              <td className="info">Default Currency</td>
              <td colSpan="2">
                <Form.Group>
                  <Form.Control
                    className="profile-field"
                    as="select"
                    defaultValue={profile.defaultCurrency}
                    onChange={onDefaultCurrencyChange}
                  >
                    <option value="EUR"> € EUR</option>
                    <option value="INR"> ₹ INR</option>
                    <option value="USD"> $ USD</option>
                  </Form.Control>
                </Form.Group>
              </td>
            </tr>
            <tr>
              <td colSpan="3">
                <center>
                  <Button variant="primary" onClick={onSubmit}>
                    Save
                  </Button>
                </center>
              </td>
            </tr>
          </tbody>
        </Table>
      )}
    </div>
  );
}
