import React, { useEffect, useRef, useState } from "react";
import { Form, Table } from "react-bootstrap";
import Constants from "../utils/Constants";
import {
  calculateValueInSelectedCurrency,
  getCurrencyCodeSymbol,
  getLocaleByCurrencyCode,
  getSessionData,
} from "../utils/FilterComponentHelper";
import { registerToSocketConnection } from "../utils/SocketInstance";
import "./../css/liveprice.scss";

function LivePrice(props) {
  const [data, setData] = useState([]);
  const selectedExchange = useRef(0);
  const [tableData, setTableData] = useState();
  const locale = getLocaleByCurrencyCode();
  const currencySymbolOptions = getCurrencyCodeSymbol();

  useEffect(() => {
    registerToSocketConnection( (apiData, arbitrageType, sessionData) => {
      let data = JSON.parse(apiData);
      setData(data);
      updateTable(data, selectedExchange.current);
    }, Constants.LIVE_PRICE);
  }, []);
  const onSelectionChange = (event) => {
    selectedExchange.current = event.target.value;
    updateTable(data, selectedExchange.current);
  };

  const updateTable = (data, selectedIndex) => {
    let sessionData = getSessionData();
    let tableData = [];
    for (let [key, value] of Object.entries(data[selectedIndex].exchangeData)) {
      let buyPriceInFiat = convertRate(
        sessionData,
        value.buyArray[0]
      ).toLocaleString(locale, currencySymbolOptions);
      let sellPriceInFiat = convertRate(
        sessionData,
        value.sellArray[0]
      ).toLocaleString(locale, currencySymbolOptions);
      let volume = +value.volume.toFixed(0);
      let withdrawalFee = +value.withdrawalFee;
      tableData.push(
        <tr key={key}>
          <td>{tableData.length + 1}</td>
          <td>{key}</td>
          <td key={key + "buy" + buyPriceInFiat}>{buyPriceInFiat}</td>
          <td key={key + "sell" + sellPriceInFiat}>{sellPriceInFiat}</td>
          <td key={key + "volume" + volume}>{volume}</td>
          <td key={key + "withdrawalFee"}>{withdrawalFee + " " + key}</td>
        </tr>
      );
    }
    setTableData(tableData);
  };

  const convertRate = (sessionData, value) => {
    return +calculateValueInSelectedCurrency(sessionData, value).toFixed(2);
  };

  return (
    <div className="liveprice-container">
      {data && (
        <React.Fragment>
          <Form.Group>
            <Form.Label className="liveprice-label">Select Exchange</Form.Label>
            <Form.Control className="" as="select" onChange={onSelectionChange}>
              {data.map((item, i) => {
                return (
                  <option key={i} value={i}>
                    {item.exchangeName.toUpperCase() +
                      " " +
                      item.subExchangeName}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
          <Table bordered>
            <thead>
              <tr>
                <th>#</th>
                <th>Coin</th>
                <th>Buy Price</th>
                <th>Sell Price</th>
                <th>Volume</th>
                <th>Withdrawal Fee</th>
              </tr>
            </thead>
            <tbody>{tableData}</tbody>
          </Table>
        </React.Fragment>
      )}
    </div>
  );
}

export default LivePrice;
