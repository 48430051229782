import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import Cookie from "js-cookie";
import React, { useState } from "react";
import { Card, FormCheck } from "react-bootstrap";
import GoogleLogin from "react-google-login";
import { Link, useHistory, useLocation } from "react-router-dom";
import "./../css/login-logout.scss";

export default function Login(props) {
  let history = useHistory();
  let location = useLocation();

  let { from } = location.state || { from: { pathname: "/" } };
  const [isAgreed, setIsAgreed] = useState(true);
  const successResponseGoogle = (response) => {
    let tokenId = response.tokenId;
    axios
      .post("/api/login", {
        tokenId: tokenId,
      })
      .then(() => {
        Cookie.set("B_A_USER", "YES", {
          expires: 1 / 12,
        });
        history.push(from);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const errResponseGoogle = (response) => {
    // history.push("/");
  };

  return (
    <div className="login-container">
      <Card className="login-card">
        <Link to="/">
          <Card.Img
            className="login-image"
            src={require("./../icons/icons/bytesgap-logo.svg")}
          />
        </Link>
        <Card.Body>
          <Card.Text className="login-text">
            Welcome to BytesGap, Sign in to explore the Arbitrage oppurtinies.
          </Card.Text>
          <GoogleLogin
            className="login-button"
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
            theme="dark"
            buttonText="Sign in with Google"
            onSuccess={successResponseGoogle}
            onFailure={errResponseGoogle}
            cookiePolicy={"single_host_origin"}
            // isSignedIn={true}
            disabled={isAgreed}
          />
        </Card.Body>
        <Card.Footer className="login-card-footer">
          <FormCheck type="checkbox" onChange={() => setIsAgreed(!isAgreed)} />{" "}
          <span>
            I agree to BytesGap <a href={"/"}> {" terms and condition"}</a>
          </span>
        </Card.Footer>
      </Card>
    </div>
  );
}
