import React, { useState } from "react";
import makeAnimated from "react-select/animated";
import AsyncSelect from "react-select/async";
import "./../../css/exchange-coin-select.scss";
import {
  getCoinsOptionByExchanges,
  sortCoinsOptions,
} from "./../../utils/FilterComponentHelper";

const animatedComponents = makeAnimated();

export default function ExchangeAndCoinSelect({
  keyName,
  showCoinSelect,
  title,
  exchangeOptions,
  coinsOptions,
  exchangeSelectCallback,
  coinSelectCallback,
  selectedExchangeOptions,
  selectedCoinsOptions,
}) {
  const [exchangeCoinSelect, setExchangeCoinSelect] = useState({
    selectedExchangeOptions: selectedExchangeOptions,
    selectedCoinsOptions: selectedCoinsOptions,
    coinSelectOptions: coinsOptions,
  });
  const exchangeLoadOptions = (inputValue) => {
    return new Promise((resolve) => {
      resolve(
        exchangeOptions.filter((i) => {
          return i.value.exchange
            .toLowerCase()
            .includes(inputValue.toLowerCase());
        })
      );
    });
  };

  function handleExchangeMenuOnChange(selectedExchangeOptions) {
    setExchangeCoinSelect((prevState) => {
      prevState = { ...prevState };

      let coinOptionsByExchange = getCoinsOptionByExchanges(
        selectedExchangeOptions,
        selectedCoinsOptions
      );
      if (coinOptionsByExchange.length === 0) {
        prevState.selectedCoinsOptions = [];
      }
      prevState.selectedCoinsOptions = coinOptionsByExchange;
      prevState.selectedExchangeOptions = selectedExchangeOptions;
      coinSelectCallback(coinOptionsByExchange || []);
      exchangeSelectCallback(selectedExchangeOptions || []);
      return prevState;
    });
  }

  function onCoinSelectChange(selectedCoinsOptions) {
    setExchangeCoinSelect((prevState) => {
      prevState = { ...prevState };
      prevState.selectedCoinsOptions = selectedCoinsOptions;
      coinSelectCallback(selectedCoinsOptions || []);
      return prevState;
    });
  }

  function onCoinSelectMenuOpen() {
    setExchangeCoinSelect((prevState) => {
      prevState = { ...prevState };
      let coinOptionsByExchange = getCoinsOptionByExchanges(
        prevState.selectedExchangeOptions,
        coinsOptions
      );
      if (coinOptionsByExchange.length === 0) {
        prevState.selectedCoinsOptions = [];
      }
      prevState.coinSelectOptions = coinOptionsByExchange;
      return prevState;
    });
    console.log("menu opened");
  }
  return (
    <React.Fragment>
        <div className="exchange-coin-select-container">
          <center className="font-medium my-1">{title}</center>
          <center>
            <AsyncSelect
              key={keyName + "ExchangeSelect"}
              closeMenuOnSelect={false}
              placeholder="Select the Exchanges "
              cacheOptions
              value={exchangeCoinSelect.selectedExchangeOptions}
              defaultOptions={exchangeOptions}
              components={{ animatedComponents }}
              isMulti
              loadOptions={exchangeLoadOptions}
              onChange={(selectedOptions) => {
                handleExchangeMenuOnChange(selectedOptions);
              }}
            />
          </center>
        </div>
        {showCoinSelect && (
          <div className="exchange-coin-select-container">
            <center>
              <CoinSelect
                key={keyName + "CoinSelect"}
                keyName={keyName + "CoinSelect"}
                isDisabled={
                  !exchangeCoinSelect.selectedExchangeOptions ||
                  exchangeCoinSelect.selectedExchangeOptions.length === 0
                }
                selectedCoinsOptions={exchangeCoinSelect.selectedCoinsOptions}
                coinSelectOptions={exchangeCoinSelect.coinSelectOptions}
                onCoinSelectChange={onCoinSelectChange}
                onCoinSelectMenuOpen={onCoinSelectMenuOpen}
              ></CoinSelect>
            </center>
          </div>
        )}
    </React.Fragment>
  );
}

function CoinSelect({
  keyName,
  isDisabled,
  selectedCoinsOptions,
  coinSelectOptions,
  onCoinSelectChange,
  onCoinSelectMenuOpen,
}) {
  const coinsLoadOptions = (inputValue) => {
    return new Promise((resolve) => {
      let options = coinSelectOptions.filter((i) => {
        return i.value.currency
          .toLowerCase()
          .includes(inputValue.toLowerCase());
      });
      sortCoinsOptions(options);
      resolve(options);
    });
  };

  return (
    <AsyncSelect
      key={keyName + "Sub"}
      closeMenuOnSelect={false}
      placeholder="Select the coins "
      cacheOptions
      value={selectedCoinsOptions}
      defaultOptions={coinSelectOptions}
      isMulti
      components={{ animatedComponents }}
      isDisabled={isDisabled}
      loadOptions={coinsLoadOptions}
      onChange={onCoinSelectChange}
      onMenuOpen={onCoinSelectMenuOpen}
    />
  );
}
