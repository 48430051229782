import Axios from "axios";
import { Button } from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import SaveIcon from "@material-ui/icons/Save";
import React, { useEffect, useState } from "react";
import ExchangeAndCoinSelect from "../../components/filtercomponents/ExchangeCoinSelect";
import Constants from "../../utils/Constants";
import FilterData from "../../utils/FilterData";

import {
  getCoinsOptionsFromData,
  getExchangeOptionsFromData,
  getRemainingExchangeOptions,
  saveFilter,
  getFilter,
  getDefaultExchangeOptions as filterFromExchangeOptionsObj,
  getDefaultCoinsOptions as filterFromCoinsOptionsObj,
  clearFilter,
} from "./../../utils/FilterComponentHelper";

export default function TriangularFilter(props) {
  const [reload, setReload] = useState(true);

  const [triangularFilter, setTriangularFilter] = useState({
    exchangeOptions: [],
    coinsOptions: [],
    // From Exchange
    fromExchangeOptions: [],
    fromCoinsOptions: [],
    selectedFromExchangeOptions: [],
    selectedFromCoinsOptions: [],

    // via Exchange
    viaExchangeOptions: [],
    viaCoinsOptions: [],
    selectedViaExchangeOptions: [],
    selectedViaCoinsOptions: [],

    // To Exchange
    toExchangeOptions: [],
    toCoinsOptions: [],
    selectedToExchangeOptions: [],
    selectedToCoinsOptions: [],
    filterOption: getFilter(Constants.TRIANGULAR_FILTER),
  });

  useEffect(() => {
    initialize().then(() => {
      setReload(true);
    });
  }, [reload]);

  async function initialize() {
    return Axios.get("/api/trackedData/triangularFilter")
      .then((res) => {
        let exchangeOptions = getExchangeOptionsFromData(res.data);
        let coinsOptions = getCoinsOptionsFromData(res.data);
        let filterOption = getFilter(Constants.TRIANGULAR_FILTER) || FilterData;

        setTriangularFilter({
          exchangeOptions: exchangeOptions,
          coinsOptions: coinsOptions,
          fromCoinsOptions: coinsOptions,
          viaCoinsOptions: coinsOptions,
          toCoinsOptions: coinsOptions,
          filterOption: filterOption,

          // From Exchange
          fromExchangeOptions: getRemainingExchangeOptions(
            exchangeOptions,
            filterOption.toExchange.concat(filterOption.viaExchange)
          ),

          selectedFromExchangeOptions: filterFromExchangeOptionsObj(
            exchangeOptions,
            filterOption.fromExchange
          ),

          selectedFromCoinsOptions: filterFromCoinsOptionsObj(
            coinsOptions,
            filterOption.fromCoins
          ),

          // From Exchange
          viaExchangeOptions: getRemainingExchangeOptions(
            exchangeOptions,
            filterOption.fromExchange.concat(filterOption.toExchange)
          ),

          selectedViaExchangeOptions: filterFromExchangeOptionsObj(
            exchangeOptions,
            filterOption.viaExchange
          ),

          selectedViaCoinsOptions: filterFromCoinsOptionsObj(
            coinsOptions,
            filterOption.viaCoins
          ),

          // To Exchange
          toExchangeOptions: getRemainingExchangeOptions(
            exchangeOptions,
            filterOption.fromExchange.concat(filterOption.viaExchange)
          ),

          selectedToCoinsOptions: filterFromCoinsOptionsObj(
            coinsOptions,
            filterOption.toCoins
          ),
          selectedToExchangeOptions: filterFromExchangeOptionsObj(
            exchangeOptions,
            filterOption.toExchange
          ),
        });
      })
      .catch((err) => {
        console.log("Unable to fetch the exchanges list");
      });
  }

  /**
   * Based on selected items from the `From` ExchangeSelect set the `Via` and `To`
   * ExchangeSelect's options
   * Via exchange options = (exchangeOptions - (selectedOptions + selectedToOptions))
   * To exchange options = (exchangeOptions - selectedOptions)
   * @param {*} selectedExchangeOptions
   */

  function fromExchangeSelectCallback(selectedExchangeOptions) {
    let remainingExchanges = getRemainingExchangeOptions(
      triangularFilter.exchangeOptions,
      selectedExchangeOptions
        .concat(triangularFilter.selectedViaExchangeOptions)
        .concat(triangularFilter.selectedToExchangeOptions)
    );
    setTriangularFilter((prevState) => {
      prevState = { ...prevState };
      prevState.viaExchangeOptions = remainingExchanges;
      prevState.toExchangeOptions = remainingExchanges;
      // For storing reults
      prevState.selectedFromExchangeOptions = selectedExchangeOptions;
      return prevState;
    });
  }

  function fromCoinSelectCallback(selectedCoinsOptions) {
    setTriangularFilter((prevState) => {
      prevState = { ...prevState };
      // For storing reults
      prevState.selectedFromCoinsOptions = selectedCoinsOptions;
      return prevState;
    });
  }

  /**
   * Based on selected items from the `Via` ExchangeSelect set the `To` and `From`
   * ExchangeSelect's options
   * From exchange options = (exchangeOptions - (selectedOptions + selectedFromOptions + selectedToOptions))
   * To exchange options = (exchangeOptions - (selectedOptions + selectedFromOptions + selectedToOptions))
   * @param {*} selectedExchangeOptions
   * @param {*} selectedCoinsOption
   */
  function viaExchangeSelectCallback(selectedExchangeOptions) {
    let remainingExchanges = getRemainingExchangeOptions(
      triangularFilter.exchangeOptions,
      selectedExchangeOptions
        .concat(triangularFilter.selectedFromExchangeOptions)
        .concat(triangularFilter.selectedToExchangeOptions)
    );
    setTriangularFilter((prevState) => {
      prevState = { ...prevState };
      prevState.fromExchangeOptions = remainingExchanges;
      prevState.toExchangeOptions = remainingExchanges;
      // For storing reults
      prevState.selectedViaExchangeOptions = selectedExchangeOptions;
      return prevState;
    });
  }

  function viaCoinSelectCallback(selectedCoinsOptions) {
    setTriangularFilter((prevState) => {
      prevState = { ...prevState };
      // For storing reults
      prevState.selectedViaCoinsOptions = selectedCoinsOptions;
      return prevState;
    });
  }
  /**
   * Based on selected items from the `To` ExchangeSelect set the `Via` and `From`
   * ExchangeSelect's options
   * Via exchange options = (exchangeOptions - (selectedToOptions + selectedFromOptions))
   * To exchange options = (exchangeOptions - selectedOptions)
   * @param {*} selectedExchangeOptions
   * @param {*} selectedCoinsOption
   */
  function toExchangeSelectCallback(selectedExchangeOptions) {
    let remainingExchanges = getRemainingExchangeOptions(
      triangularFilter.exchangeOptions,
      selectedExchangeOptions
        .concat(triangularFilter.selectedViaExchangeOptions)
        .concat(triangularFilter.selectedFromExchangeOptions)
    );
    setTriangularFilter((prevState) => {
      prevState = { ...prevState };
      prevState.viaExchangeOptions = remainingExchanges;
      prevState.fromExchangeOptions = remainingExchanges;

      // For storing reults
      prevState.selectedToExchangeOptions = selectedExchangeOptions;
      return prevState;
    });
  }

  function toCoinSelectCallback(selectedCoinsOptions) {
    setTriangularFilter((prevState) => {
      prevState = { ...prevState };
      // For storing reults
      prevState.selectedToCoinsOptions = selectedCoinsOptions;
      return prevState;
    });
  }

  function isEverythingLoaded() {
    return (
      triangularFilter.exchangeOptions.length > 0 &&
      triangularFilter.coinsOptions.length > 0 &&
      reload
    );
  }
  return (
    <React.Fragment>
      {isEverythingLoaded() && (
        <div>
          <center>
            <ExchangeAndCoinSelect
              key="triangularFrom"
              keyName="triangularFrom"
              title="From"
              exchangeSelectCallback={fromExchangeSelectCallback}
              coinSelectCallback={fromCoinSelectCallback}
              selectedExchangeOptions={
                triangularFilter.selectedFromExchangeOptions
              }
              selectedCoinsOptions={triangularFilter.selectedFromCoinsOptions}
              exchangeOptions={triangularFilter.fromExchangeOptions}
              coinsOptions={triangularFilter.fromCoinsOptions}
              showCoinSelect ={true}
            ></ExchangeAndCoinSelect>
          </center>
          <center>
            <ExchangeAndCoinSelect
              key="triangularVia"
              title="Via"
              keyName="triangularVia"
              exchangeSelectCallback={viaExchangeSelectCallback}
              coinSelectCallback={viaCoinSelectCallback}
              selectedExchangeOptions={
                triangularFilter.selectedViaExchangeOptions
              }
              selectedCoinsOptions={triangularFilter.selectedViaCoinsOptions}
              exchangeOptions={triangularFilter.viaExchangeOptions}
              coinsOptions={triangularFilter.viaCoinsOptions}
              showCoinSelect ={true}
            ></ExchangeAndCoinSelect>
          </center>
          <center>
            <ExchangeAndCoinSelect
              key="triangularTo"
              keyName="triangularTo"
              title="To"
              exchangeSelectCallback={toExchangeSelectCallback}
              coinSelectCallback={toCoinSelectCallback}
              selectedExchangeOptions={
                triangularFilter.selectedToExchangeOptions
              }
              selectedCoinsOptions={triangularFilter.selectedToCoinsOptions}
              exchangeOptions={triangularFilter.toExchangeOptions}
              coinsOptions={triangularFilter.toCoinsOptions}
              showCoinSelect ={false}
            ></ExchangeAndCoinSelect>
          </center>
          <center>
            <Button
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              className="filter-save-button"
              onClick={() => {
                saveFilter(Constants.TRIANGULAR_FILTER, {
                  fromExchange: triangularFilter.selectedFromExchangeOptions,
                  fromCoins: triangularFilter.selectedFromCoinsOptions,
                  viaExchange: triangularFilter.selectedViaExchangeOptions,
                  viaCoins: triangularFilter.selectedViaCoinsOptions,
                  toExchange: triangularFilter.selectedToExchangeOptions,
                  toCoins: triangularFilter.selectedToCoinsOptions,
                });
              }}
            >
              Save
            </Button>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<DeleteForeverIcon />}
              className="filter-clear-button"
              onClick={() => {
                clearFilter(Constants.TRIANGULAR_FILTER);
                setReload(false);
              }}
            >
              Discard
            </Button>
          </center>
        </div>
      )}
    </React.Fragment>
  );
}
