import React, { useContext, useState } from "react";
import { BiShapeTriangle } from "react-icons/bi";
import { BsArrowLeftRight, BsArrowRepeat } from "react-icons/bs";
import {
  HiArrowNarrowRight,
  HiFilter,
  HiLogout,
  HiTrendingUp,
  HiUser,
} from "react-icons/hi";
import { IoPulseSharp } from "react-icons/io5";
import { FaDonate } from "react-icons/fa";
import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarContent,
  SubMenu,
} from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { SidebarContext } from "./../context/SidebarContext";
import "./../css/sidebar.scss";

const Sidebar = () => {
  const [sidebarState, setSidebarState] = useContext(SidebarContext);
  const handleToggleSidebar = (value) => {
    setSidebarState((prevState) => {
      prevState = { ...prevState };
      prevState.toggled = !prevState.toggled;
      return prevState;
    });
  };

  return (
    <ProSidebar
      collapsed={sidebarState.isCollapsed}
      breakPoint="md"
      toggled={sidebarState.toggled}
      onToggle={handleToggleSidebar}
    >
      <SidebarContent>
        <Menu iconShape="circle">
          <SubMenu title="Arbitrage" defaultOpen={true} icon={<HiTrendingUp />}>
            <MenuItem icon={<BsArrowLeftRight />} onClick={handleToggleSidebar}>
              Intra Exchange
              <Link to="/dashboard/intra-exchange" />
            </MenuItem>
            <MenuItem
              icon={<HiArrowNarrowRight />}
              onClick={handleToggleSidebar}
            >
              Direct
              <Link to="/dashboard/direct" />
            </MenuItem>
            <MenuItem icon={<BsArrowRepeat />} onClick={handleToggleSidebar}>
              Loop
              <Link to="/dashboard/loop" />
            </MenuItem>
            <MenuItem icon={<BiShapeTriangle />} onClick={handleToggleSidebar}>
              Triangular
              <Link to="/dashboard/triangular" />
            </MenuItem>
          </SubMenu>
          <MenuItem icon={<HiFilter />} onClick={handleToggleSidebar}>
            Filter
            <Link to="/filter" />
          </MenuItem>
          <MenuItem icon={<IoPulseSharp />} onClick={handleToggleSidebar}>
            Live Price
            <Link to="/liveprice" />
          </MenuItem>
          <MenuItem icon={<HiUser />} onClick={handleToggleSidebar}>
            Profile
            <Link to="/profile" />
          </MenuItem>
          <MenuItem icon={<HiUser />} onClick={handleToggleSidebar}>
            FAQ
            <Link to="/faq" />
          </MenuItem>
          <MenuItem icon={<FaDonate />} onClick={handleToggleSidebar}>
            Donate
            <Link to="/donate" />
          </MenuItem>
          <MenuItem icon={<HiLogout />} onClick={handleToggleSidebar}>
            Logout
            <Link to="/logout" />
          </MenuItem>
        </Menu>
      </SidebarContent>
    </ProSidebar>
  );
};

export default Sidebar;
