import { Button } from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import SaveIcon from "@material-ui/icons/Save";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import ExchangeAndCoinSelect from "../../components/filtercomponents/ExchangeCoinSelect";
import Constants from "../../utils/Constants";
import FilterData from "../../utils/FilterData";
import {
  clearFilter,
  getCoinsOptionsFromData,
  getDefaultCoinsOptions as filterFromCoinsOptionsObj,
  getDefaultExchangeOptions as filterFromExchangeOptionsObj,
  getExchangeOptionsFromData,
  getFilter,
  getRemainingExchangeOptions,
  getRemainingSameExchangeOptions,
  saveFilter,
} from "../../utils/FilterComponentHelper";

export default function IntraExchangeFilter(props) {
  const [reload, setReload] = useState(true);

  const [intraExchangeFilter, setIntraExchangeFilter] = useState({
    exchangeOptions: [],
    coinsOptions: [],
    // From Exchange
    fromExchangeOptions: [],
    fromCoinsOptions: [],
    selectedFromExchangeOptions: [],
    selectedFromCoinsOptions: [],
    // To Exchange
    toExchangeOptions: [],
    toCoinsOptions: [],
    selectedToExchangeOptions: [],
    selectedToCoinsOptions: [],
    filterOption: getFilter(Constants.INTRA_EXCHANGE_FILTER),
  });

  useEffect(() => {
    initialize().then(() => {
      setReload(true);
    });
  }, [reload]);

  async function initialize() {
    return Axios.get("/api/trackedData/intraExchangeFilter")
      .then((res) => {
        let exchangeOptions = getExchangeOptionsFromData(res.data);
        let coinsOptions = getCoinsOptionsFromData(res.data);
        let filterOption = getFilter(Constants.INTRA_EXCHANGE_FILTER) || FilterData;

        setIntraExchangeFilter({
          exchangeOptions: exchangeOptions,
          coinsOptions: coinsOptions,
          fromCoinsOptions: coinsOptions,
          toCoinsOptions: coinsOptions,
          filterOption: filterOption,

          // From Exchange
          fromExchangeOptions: getRemainingExchangeOptions(
            exchangeOptions,
            filterOption.toExchange
          ),

          selectedFromExchangeOptions: filterFromExchangeOptionsObj(
            exchangeOptions,
            filterOption.fromExchange
          ),

          selectedFromCoinsOptions: filterFromCoinsOptionsObj(
            coinsOptions,
            filterOption.fromCoins
          ),

          // To Exchange
          toExchangeOptions: getRemainingExchangeOptions(
            exchangeOptions,
            filterOption.fromExchange
          ),

          selectedToCoinsOptions: filterFromCoinsOptionsObj(
            coinsOptions,
            filterOption.toCoins
          ),
          selectedToExchangeOptions: filterFromExchangeOptionsObj(
            exchangeOptions,
            filterOption.toExchange
          ),
        });
      })
      .catch((err) => {
        console.log("Unable to fetch the exchanges list");
      });
  }

  /**
   * Based on selected items from the `From` ExchangeSelect set the `To` ExchangeSelect's
   * options (exchangeOptions - selectedFromOptions) = options of Same Exchange
   * @param {*} selectedExchangeOptions
   */
  function fromExchangeSelectCallback(selectedExchangeOptions) {
    let remainingExchanges = getRemainingSameExchangeOptions(
      intraExchangeFilter.exchangeOptions,
      selectedExchangeOptions
    );
    setIntraExchangeFilter((prevState) => {
      prevState = { ...prevState };
      prevState.toExchangeOptions = remainingExchanges;

      // For storing reults
      prevState.selectedFromExchangeOptions = selectedExchangeOptions;
      return prevState;
    });
  }

  function fromCoinSelectCallback(selectedCoinsOptions) {
    setIntraExchangeFilter((prevState) => {
      prevState = { ...prevState };
      // For storing reults
      prevState.selectedFromCoinsOptions = selectedCoinsOptions;
      return prevState;
    });
  }

  /**
   * Based on selected items from the `To` ExchangeSelect set the `From` ExchangeSelect's
   * options (exchangeOptions - selectedToOptions) = options of Same Exchange
   * @param {*} selectedExchangeOptions
   */
  function toExchangeSelectCallback(selectedExchangeOptions) {
    let remainingExchanges = getRemainingSameExchangeOptions(
      intraExchangeFilter.exchangeOptions,
      selectedExchangeOptions
    );
    setIntraExchangeFilter((prevState) => {
      prevState = { ...prevState };
      prevState.fromExchangeOptions = remainingExchanges;

      // For storing reults
      prevState.selectedToExchangeOptions = selectedExchangeOptions;
      return prevState;
    });
  }

  function toCoinSelectCallback(selectedCoinsOptions) {
    setIntraExchangeFilter((prevState) => {
      prevState = { ...prevState };
      prevState.selectedToCoinsOptions = selectedCoinsOptions;
      return prevState;
    });
  }
  function isEverythingLoaded() {
    return (
      intraExchangeFilter.exchangeOptions.length > 0 &&
      intraExchangeFilter.coinsOptions.length > 0 &&
      reload
    );
  }
  return (
    <React.Fragment>
      {isEverythingLoaded() && (
        <div>
          <center>
            <ExchangeAndCoinSelect
              key="intraExchangeFrom"
              keyName="intraExchangeFrom"
              title="From"
              exchangeSelectCallback={fromExchangeSelectCallback}
              coinSelectCallback={fromCoinSelectCallback}
              selectedExchangeOptions={
                intraExchangeFilter.selectedFromExchangeOptions
              }
              selectedCoinsOptions={intraExchangeFilter.selectedFromCoinsOptions}
              exchangeOptions={intraExchangeFilter.fromExchangeOptions}
              coinsOptions={intraExchangeFilter.fromCoinsOptions}
              showCoinSelect ={true}
            ></ExchangeAndCoinSelect>
          </center>

          <center>
            <ExchangeAndCoinSelect
              key="intraExchangeTo"
              keyName="intraExchangeTo"
              title="To"
              exchangeSelectCallback={toExchangeSelectCallback}
              coinSelectCallback={toCoinSelectCallback}
              selectedExchangeOptions={intraExchangeFilter.selectedToExchangeOptions}
              selectedCoinsOptions={intraExchangeFilter.selectedToCoinsOptions}
              exchangeOptions={intraExchangeFilter.toExchangeOptions}
              coinsOptions={intraExchangeFilter.toCoinsOptions}
              showCoinSelect ={false}
            ></ExchangeAndCoinSelect>
          </center>
          <center>
            <Button
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              className="filter-save-button"
              onClick={() => {
                saveFilter(Constants.INTRA_EXCHANGE_FILTER, {
                  fromExchange: intraExchangeFilter.selectedFromExchangeOptions,
                  fromCoins: intraExchangeFilter.selectedFromCoinsOptions,
                  toExchange: intraExchangeFilter.selectedToExchangeOptions,
                  toCoins: intraExchangeFilter.selectedToCoinsOptions,
                });
              }}
            >
              Save
            </Button>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<DeleteForeverIcon />}
              className="filter-clear-button"
              onClick={() => {
                clearFilter(Constants.INTRA_EXCHANGE_FILTER);
                setReload(false);
              }}
            >
              Discard
            </Button>
          </center>
        </div>
      )}
    </React.Fragment>
  );
}
