export default class Constants {
  static BYTES_GAP_FILTER = "BYTES_GAP_FILTER";
  static DIRECT_FILTER = "DIRECT_FILTER";
  static INTRA_EXCHANGE_FILTER = "INTRA_EXCHANGE_FILTER";
  static LOOP_FILTER = "LOOP_FILTER";
  static TRIANGULAR_FILTER = "TRIANGULAR_FILTER";
  static LIVE_PRICE = "LIVE_PRICE";

  static MIN_USD = 50;
  static MIN_EUR = 50;
  static MIN_INR = 2000;
  static SAVE_FEATURE = false;
  static ALERT_FEATURE = false;
}
